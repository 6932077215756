export default 
[
      { path: '/dashboard/admin/user/add', 
      props: true, 
      name: 'admin_add_user', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/user/Add.vue') },

      { path: '/dashboard/admin/user/edit/:user_id', 
      props: true, 
      name: 'admin_edit_user', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/user/Add.vue') },

      { path: '/dashboard/admin/user/list', 
      props: true, 
      name: 'admin_list_user', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/user/List.vue') },

      { path: '/dashboard/admin/user/read/:user_id', 
      props: true, 
      name: 'admin_user_read', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/user/Read.vue') },

]