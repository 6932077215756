export default [
  {
    path: '/dashboard/admin/deal/customer/add/:deal_id',
    props: true,
    name: 'deal_add_customer',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/customer/Add.vue'),
  },

  {
    path: '/dashboard/admin/customer/edit/:customer_id',
    props: true,
    name: 'customer_edit',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/customer/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal/assign_deal_summary/:deal_summary_id',
    props: true,
    name: 'assign_deal_summary',
    meta: { adminOnly: true },
    component: () =>
      import('@/views/admin/deal/deal_summary/AssignLenders.vue'),
  },

  {
    path: '/dashboard/admin/deal/resend_to_lenders/:deal_summary_id',
    props: true,
    name: 're-assign_deal_summary',
    meta: { adminOnly: true },
    component: () =>
      import('@/views/admin/deal/deal_summary/ResendToLenders.vue'),
  },

  {
    path: '/dashboard/admin/deal/list',
    name: 'deal_list',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/List.vue'),
  },

  {
    path: '/dashboard/admin/deal_summary/add/:deal_id',
    props: true,
    name: 'deal_summary_add',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/deal_summary/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal_summary/edit/:deal_summary_id',
    props: true,
    name: 'deal_summary_edit',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/deal_summary/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal/read/:deal_id',
    props: true,
    name: 'dealRead',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/Read.vue'),
  },

  {
    path: '/dashboard/admin/deal/add',
    props: true,
    name: 'deal_add',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal/edit/:deal_id',
    props: true,
    name: 'deal_edit',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/Add.vue'),
  },
];
