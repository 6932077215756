export default function () {
  return {
    token: null,
    expiresOn: null,
    loggedIn: false,
    user: {
      first_name: null,
      last_name: null,
      id: null,
      admin: null,
      companies: {}
    }
  }
}
