import VueRouter from 'vue-router';

import API from '../../plugins/API';

import router from '../../router';

// if (process.env.VUE_APP_ENVIRONMENT === 'dev') {
//  // do something here
// } else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
//   // redirectUri = process.env.VUE_APP_STAGING_SPA_URL;
// } else if (process.env.VUE_APP_ENVIRONMENT === 'prod') {
//   // redirectUri = process.env.VUE_APP_SPA_URL;
// }



export async function jwt_login({ commit }, payload) {
  console.log('jwt_login is running now logging payload')
  console.log(payload)

  commit('setToken', payload.jwt);

  let userInfo = await API().get(`/api/user-info`)

  console.log(userInfo)

  commit('setAuthUserInfo', userInfo.data)



}


// export async function login({ getters, dispatch }) {

//   if (getters.getToken) {

//     await dispatch('silentLogin');

//   } else {


//     await dispatch('silentLogin');

//   }

// }

// export async function silentLogin({ commit, dispatch, getters }) {

  // if (!tokenResponse.error && tokenResponse.accessToken) {
  //   commit('setToken', {

  //   });

  // }

// }

// export async function getAccountInfo({ commit }) {




// }

export async function logout({ commit }) {
  console.log('logout')
  commit('logout');
  localStorage.clear();
  sessionStorage.clear();
  window.localStorage.clear();
  window.localStorage.removeItem('vuex');

  router.push('/')

}


