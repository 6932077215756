import router from "../../router";

export function setToken(state, value) {

    console.log('setToken value is:')
    console.log(value)

    state.token = value;

    // state.expiresOn = value.expiresOn;
    localStorage.setItem('token', value)
}

export function logout(state) {
    // Object.keys(state).forEach(key => { state[key] = {} })
    // state.token = null;
    // state.loggedIn = false;
    // state.expiresOn = null;


    localStorage.clear();
    sessionStorage.clear();
    window.localStorage.clear();
    window.localStorage.removeItem('vuex');
    state.loggedIn = false;
    state.token = null;
    state.user = {};



}

export function setAuthUserInfo(state, value) {

    console.log('mutation setAuthUserInfo value')
    console.log(value)
    state.user.id = value.id;
  
    state.user.first_name = value.first_name;
    state.user.last_name = value.last_name;
    state.user.id = value.id;
    state.user.companies = value.companies;
    state.user.lender = value.lender;
    state.user.lawyer = value.lawyer;
    state.user.appraiser = value.appraiser;
    state.user.role = value.role.name;

    if(value.role.name == "Internal"){
        state.user.admin = true
    } else {
        state.user.admin = false
    }
    
    state.loggedIn = true;


}