import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import authRoutes from './auth';
import companyRoutes from './company';
import adminDealRoutes from './adminDeal';
import adminUserRoutes from './adminUser';
import adminForm from './adminForm';

import store from '../store';
import adminKanban from './adminKanban';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...adminDealRoutes,
  ...companyRoutes,
  ...adminUserRoutes,
  ...adminForm,
  ...adminKanban,

  {
    path: '/',
    name: 'Home',

    component: () => import('@/views/Home.vue'),
  },

  {
    path: '/dashboard/mortgagesAvailable/list',
    meta: { loggedIn: true },
    props: true,
    name: 'mortgagesAvailable',

    component: () => import('@/views/mortgagesAvailable/List.vue'),
  },

  {
    path: '/dashboard/mortgages/my-bids',
    meta: { loggedIn: true },
    props: true,
    name: 'mortgagesMyBids',

    component: () => import('@/views/mortgagesAvailable/MyBids.vue'),
  },

  {
    path: '/dashboard/mortgagesAvailable/read/:deal_summary_id',
    props: true,
    name: 'mortgagesAvailableReadDeal',
    meta: { loggedIn: true },
    component: () => import('@/views/mortgagesAvailable/Read.vue'),
  },

  {
    path: '/summary_link/:deal_summary_uuid/',
    props: true,
    name: 'read_deal_summary_link',
    // does not need to be logged in because has token in the link
    meta: {},
    component: () => import('@/views/summary_link/Read.vue'),
  },

  {
    path: '/myAccount/info',
    name: 'MyAccountInfo',
    meta: { loggedIn: true },
    component: () => import('@/views/user/myAccountInfo.vue'),
  },

  {
    path: '/myAccount/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/user/ResetPassword.vue'),
  },

  {
    path: '/myAccount/new-password',
    name: 'NewPassword',
    component: () => import('@/views/user/NewPassword.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// NavGaurds

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.adminOnly)) {
    if (
      store.getters['Auth/getAuthUser'].admin &&
      store.getters['Auth/getLoggedIn']
    ) {
      next();
    } else {
      // does not pass, go to home
      next('/');
    }
  } else if (to.matched.some((record) => record.meta.loggedIn)) {
    if (store.getters['Auth/getLoggedIn']) {
      next();
    } else {
      // does not pass, go to home
      next('/');
    }
  } else {
    // does not match record.mta.adminOnly
    next();
  }
});

export default router;
