export default 

[{
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
},

{
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout.vue'),
}]