<template>
  <div class="dashboard">
    <br />
    <h1 class="white--text">Lender Portal</h1>
    <br />

    <p>
      Welcome to the lender portal. 
    </p>
    <p>As a registered lender, you will be able to
      do the following regarding lending opportunities:</p>
    <ul>
      <li>View current available mortgages for funding or sale</li>
      <li>Submit offers on mortgages</li>
      <li>View current offers on lending opportunity</li>
      <li v-if='$store.getters["Auth/getLoggedIn"]'>Track status of the funding of your loans <span style="color: green !important;">(Coming Soon!)</span></li>
      <li v-if='$store.getters["Auth/getLoggedIn"]'>Make service requests for your loans <span style="color: green !important;">(Coming Soon!)</span>
        <br v-if='$store.getters["Auth/getLoggedIn"]'>
        <ul v-if='$store.getters["Auth/getLoggedIn"]'>
          <li>NSF notifications</li>
          <li>Payout requests</li>
          <li>Insurance requests</li>
        </ul>
      </li>
    </ul>
    <br />
    <p v-if='!$store.getters["Auth/getLoggedIn"]'>
      To gain lender access, you must be an EquityApproved.ca approved and onboarded lender. Please contact us to inquire about joining our network.
    </p>

  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      quote: null,
    };
  },
  components: {},
  computed: {
    homeImg() {
      return this.$store.getters["Auth/getLoggedIn"];
    },
  },

  async created() {

    

  },
  async mounted() {





    // if ('account' in this.$store.getters["Auth/getAuthUser"] && 'Companies' in this.$store.getters["Auth/getAuthUser"].account) {

     
    //   if (!this.$store.getters["Auth/getAuthUser"].account.Companies.length) {
    //     this.$router.push('/companyAccountApplication')
    //   }

    // }




  },
};
</script>

<style scoped>
.gifWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cannonGif {
  min-width: 700px;
  max-width: 700px;
  min-height: 400px;
  max-height: 400px;
  margin-top: 5%;
  border: 10px solid rgb(39, 39, 39);
}
</style>
